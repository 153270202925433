<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <div class="headline">Nuevo cliente</div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12"> </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <cliente-form
          @close-dialog="$emit('close-dialog')"
          :cliente="cliente"
          :provincias="provincias"
          :isUpdate="false"
          @processCliente="registrarCliente"
          :planDeCuentas="planDeCuentas"
        ></cliente-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ClienteForm from "@/components/cliente/ClienteForm";
import { mapActions, mapState } from "vuex";
export default {
  name: "ClienteCreate",
  components: {
    ClienteForm,
  },
  props: {
    planDeCuentas: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      provincias: [],
      cliente: {
        persona: {
          nombre: "",
          apellido: "",
          documento: "",
          telefono: "",
          email: "",
          direccion: null,
          localidad: { id: null },
          tipo_documento: null,
        },
      },
    };
  },
  computed: {
    ...mapState("contabilidad", ["planDeCuentas"]),
  },

  methods: {
    ...mapActions("cliente", ["registrar_cliente"]),
    ...mapActions("contabilidad", ["fetchPlanDeCuentas"]),
    async fetchCuentas() {
      try {
        await this.fetchPlanDeCuentas({ empresaUuid: null, isActive: true });
      } catch (error) {
        console.error("Error al obtener el plan de cuentas:", error);
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "info",
          text: "Error al obtener el plan de cuentas",
          icon: "mdi-information",
          timeout: 3000,
        });
      }
    },

    registrarCliente(cliente) {
      console.log(cliente);
      this.registrar_cliente(cliente)
        .then(() => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Cliente registrado",
            icon: "mdi-bell-ring",
            timeout: 3000,
          });
          this.$emit("close-dialog");
        })
        .catch((e) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "info",
            text: "Problema ineseperado",
            icon: "mdi-information",
            timeout: 3000,
          });
        });
    },
    async fetchProvincias() {
      try {
        const provincias = await this.axios.get("/soporte/provincias");

        this.provincias = provincias.data;
      } catch (error) {}
    },
  },
  mounted() {
    this.fetchProvincias();
    this.fetchCuentas();
  },
};
</script>

<style lang="scss" scoped></style>
