<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <div class="headline">Plan de cuentas</div>
      </v-col>
      <v-col cols="12">
        <p class="subtitle">
          Haz clic derecho en el nombre de la cuenta para Añadir, modificar o
          eliminar una cuenta.
        </p>
      </v-col>
    </v-row>
    <CuentasTreeview
      :planDeCuentas="planDeCuentas"
      @process-click-right="openMenu"
    ></CuentasTreeview>
    <v-menu
      absolute
      fixed
      transition="scale-transition"
      v-model="menu"
      :position-x="xMenu"
      :position-y="yMenu"
    >
      <v-list>
        <v-list-item @click="setDialogRegister()">
          <v-list-item-avatar>
            <v-icon color="green">mdi-plus</v-icon>
          </v-list-item-avatar>

          <v-list-item-title>Añadir cuenta</v-list-item-title>
        </v-list-item>
        <v-list-item @click="setDialogUpdate()">
          <v-list-item-avatar>
            <v-icon>mdi-pencil</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Editar</v-list-item-title>
        </v-list-item>
        <v-list-item @click="dialogEliminar = true">
          <v-list-item-avatar>
            <v-icon color="red">mdi-delete</v-icon>
          </v-list-item-avatar>

          <v-list-item-title>Eliminar</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog width="800px" v-if="dialogRegister" v-model="dialogRegister">
          <ContabilidadRegister
            :parent="parent"
            @set_cuenta="addNode"
            @close-dialog="setDialogRegister"
          ></ContabilidadRegister>
    </v-dialog>
    <v-dialog width="800px" v-if="dialogUpdate" v-model="dialogUpdate">
      <v-card>
        <v-container>
          <ContabilidadUpdate
            :cuenta="parent"
            @close-dialog="setDialogUpdate"
          ></ContabilidadUpdate>
        </v-container>
      </v-card>
    </v-dialog>
    <dialog-confirmation-close
      v-if="dialogEliminar"
      @close-dialog="dialogEliminar = false"
      @process-accept="eliminarCuenta()"
    >
      <template v-slot:titulo>¿Eliminar la cuenta?</template>
      <template v-slot:message
        >¿Seguro que quieres eliminar la cuenta "{{ parent.nombre }}"? No podrá
        revertir los cambios.</template
      >
      <template v-slot:buttonOK>Eliminar</template>
    </dialog-confirmation-close>
  </v-container>
</template>

<script>
import ContabilidadRegister from "@/components/contabilidad/ContabilidadRegister.vue";
import ContabilidadUpdate from "@/components/contabilidad/ContabilidadUpdate.vue";
import DialogConfirmationClose from "@/components/dialogs/confirmation.close";
import CuentasTreeview from "@/components/contabilidad/CuentasTreeview.vue";

import { mapActions, mapMutations } from "vuex";
export default {
  name: "ContabilidadPlanDeCuentas",
  components: {
    ContabilidadRegister,
    ContabilidadUpdate,
    DialogConfirmationClose,
    CuentasTreeview,
  },
  props: {
    planDeCuentas: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      search: null,
      caseSensitive: false,

      tree: null,
      menu: false,
      xMenu: 0,
      yMenu: 0,
      parent: null,
      dialogRegister: false,
      dialogUpdate: false,
      dialogEliminar: false,
    };
  },
  computed: {
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item.nombre.indexOf(search) > -1
        : undefined;
    },
  },
  methods: {
    ...mapActions("contabilidad", [
      "registrar_cuenta",
      "eliminar_cuenta",
    ]),
    ...mapMutations("contabilidad", ["REMOVE_CUENTA"]),

    setDialogRegister() {
      this.dialogRegister = !this.dialogRegister;
    },
    setDialogUpdate() {
      this.dialogUpdate = !this.dialogUpdate;
    },
    openMenu(data) {
      const { cuenta, event } = data;
      this.parent = { ...cuenta };
      event.preventDefault();
      this.xMenu = event.pageX;
      this.yMenu = event.pageY;
      this.menu = true;
    },

    addNode(cuenta) {
      this.parent.children.push(cuenta);
      this.parent = null;
      this.setDialogRegister();
    },
    eliminarCuenta() {
      this.eliminar_cuenta(this.parent).then((response) => {
        this.REMOVE_CUENTA(this.parent.id);
        this.dialogEliminar = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>