<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <AsientoContableListItem
          v-for="asiento in asientos_contable"
          :key="asiento.id"
          :asiento="asiento"
        >
        </AsientoContableListItem>
      </v-col>
    </v-row>
     <v-row>
      <v-col>
        <v-pagination
          v-if="asientos_contable"
          @input="paginationChangeHandler"
          :length="pages"
          :value="paginacion.page"
          circle
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import AsientoContableListItem from "@/components/contabilidad/asiento/mantenimiento/AsientoContableListItem.vue";

import AsientoTipoDetalleItem from "@/components/contabilidad/asiento/asientotipo/AsientoTipoDetalleItem.vue";

export default {
  name: "AsientoTipoList",
  components: {
    AsientoContableListItem,
    AsientoTipoDetalleItem,
  },
  data() {
    return {
      dialogEliminar: false,
         paginacion: { page: 1, cantidad: 0, rowsPerPage: 10 },
    };
  },

  props: {
    asientos: {
      type: Array,
      required: false,
    },
    dateFilter: {
      type: String,
      required: false
    }
  },

  computed: {
    ...mapState("comprobantes", ["asientos_contable"]),
     pages() {
      if (this.paginacion.rowsPerPage == null || this.paginacion.cantidad < 0) {
        return 0;
      }

      return Math.ceil(this.paginacion.cantidad / this.paginacion.rowsPerPage);
    },
  },
  methods: {
    ...mapActions("comprobantes", ["fetch_asientos_contables"]),
     paginationChangeHandler(pageNumber) {
      if (this.paginacion.page == pageNumber) {
        return false;
      }
      this.setPage(pageNumber)
    },
     setPage(pageNumber) {
      this.paginacion.page = pageNumber;
      this.fetchAsientosContables(this.paginacion);
    },
    fetchAsientosContables() {
      this.fetch_asientos_contables({
        paginacion: this.paginacion,
        dateFilter: this.dateFilter
      })
      .then(response => {
         this.paginacion.cantidad = response.data.count;
      })

    },
  },
 
  watch: {
    dateFilter(val) {
      console.log("Watch DateFilter: ,", val);
      
      this.fetchAsientosContables();
    },
  },
};
</script>