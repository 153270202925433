<template>
  <LayoutBasic :showLinksMenu="false" :routeBack="-1" v-if="sucursal">
    <template v-slot:breadcrumbs>
      <v-breadcrumbs class="pa-3 ml-1" :items="navigation" divider=">">
        <v-breadcrumbs-item
          slot="item"
          slot-scope="{ item }"
          exact
          :to="item.to"
          >{{ item.text }}</v-breadcrumbs-item
        >
      </v-breadcrumbs>
    </template>

    <template v-slot:navigation-title>
      {{ sucursal.nombre }}
    </template>
    <template v-slot:navigation-subtitle>
      {{ sucursal.empresa.razonSocial }}
    </template>

    <template v-slot:navigation-content>
      <!-- <v-img
        :src="empleado.foto || noImage"
        :lazy-src="`https://picsum.photos/10/6?image=${15}`"
        aspect-ratio="1"
        height="150px"
        contain
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img> -->

      <v-divider light></v-divider>
      <v-list>
        <v-list-item @click="$router.push({ name: 'sucursalDatosGenerales' })">
          <v-list-item-icon>
            <v-icon>mdi-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Datos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="
            $router.push({
              name: 'sucursalEditar',
              params: { sucursal: sucursal },
            })
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Editar sucursal</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
       
        <v-list-item
          @click="
            $router.push({
              name: 'sucursalDueño',
              params: { sucursal: sucursal },
            })
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Dueños</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="
            $router.push({
              name: 'sucursalesEmpleados',
              params: { sucursal: sucursal },
            })
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Empleados</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="
            $router.push({
              name: 'sucursalContabilidad',
              params: { sucursal: sucursal },
            })
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Contabilidad</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>

    <template v-slot:vista-previa>
      <v-container>
        <router-view :sucursal="sucursal"></router-view>
      </v-container>
    </template>
  </LayoutBasic>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";
import ClienteListCtaCteDetalle from "@/components/cliente/ClienteListCtaCteDetalle";
import EmpresaAPI from "@/api/empresas";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "SucursalDetail",
  components: {
    ClienteListCtaCteDetalle,
    LayoutBasic,
  },
  data() {
    return {
      search: "",
      loading: false,
      sucursal: null,

      noImage: require("@/assets/man-woman.jpg"),
      navigation: [
        {
          text: "Empresas",
          disabled: false,
          to: { name: "empresasList" },
        },
      ],
    };
  },

  methods: {
    async getSucursal(uuid) {
      try {
        this.loading = true;
        const url = `/sucursales/${uuid}`;
        const response = await this.axios.get(url);

        this.sucursal = response.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
  },

  watch: {
    sucursal: {
      handler: function (sucursal) {
        if (sucursal) {
          const item = {
            text: "Sucursales",
            disabled: false,
            to: {
              name: "empresaSucursales",
              params: { uuid_empresa: sucursal.empresa.uuid_empresa },
            },
          };
          this.navigation.push(item);
        }
      },
    },
    "$route.params": {
      handler: function (params) {
        console.log(params);
        if (params.sucursal) {
          this.sucursal = params.sucursal;
        } else {
          this.getSucursal(params.uuid_sucursal);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
