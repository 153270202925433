<template>
    <LayoutBasic :showLinksMenu="false" :routeBack="'/compras/remitos'">

    <template v-slot:breadcrumbs>
      <v-breadcrumbs class="pa-3 ml-1" :items="navigation" divider=">">
        <v-breadcrumbs-item
          slot="item"
          slot-scope="{ item }"
          exact
          :to="item.to"
          >{{ item.text }}</v-breadcrumbs-item
        >
      </v-breadcrumbs>
    </template>
   <template v-slot:navigation-title> Consultar factura </template>
           <template v-slot:navigation-content>
      <v-container>
        <v-row>
          <v-col>
            <div style="font-size: 14px">
              Ingresa los últimos números sin los ceros de la izquierda
            </div>
            <remito-buscador
              class="mt-2"
              @resultado-buscar-producto="setPedidosfromBuscador"
              @clear-search="fetchRemitos"

              tipoComprobante="compras"
            ></remito-buscador>
          </v-col>
          <v-col cols="12" lg="12">
            <proveedor-list-select
              @set-proveedor="setProveedor"
            ></proveedor-list-select>
          </v-col>
          <v-col cols="12" lg="12">
            <v-select
              v-model="estado"
              :items="estados"
              required
              label="Estado"
              @change="fetchRemitos"
              clearable
              outlined
            ></v-select>
          </v-col>

          <v-col cols="12" sm="12" md="6">
            <v-menu
              v-model="menu2"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  v-model="fechaInicio"
                  label="Fecha desde"
                  prepend-icon="mdi-calendar"
                  readonly
                  color="green lighten-1"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="green lighten-1"
                v-model="fechaInicio"
                no-title
                :max="moment().format('YYYY-MM-DD')"
                locale="es"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-menu
              v-model="menu"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  v-model="fechaFin"
                  prepend-icon="mdi-calendar"
                  label="Fecha hasta"
                  readonly
                  color="green lighten-1"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="green lighten-1"
                v-model="fechaFin"
                no-title
                :max="moment().format('YYYY-MM-DD')"
                :min="fechaInicio"
                locale="es"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="12">
            <v-btn large color="info" block @click="fetchRemitos"
              >Consultar</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:vista-previa>
      <v-container v-if="loading">
        <v-row v-for="i in 3" :key="i">
          <v-col cols="12">
            <v-skeleton-loader
              max-height="350"
              type="list-item-avatar-three-line"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
        <v-list-item
          >{{ moment(fechaInicio).format("LL") }} al
          {{ moment(fechaFin).format("LL") }}</v-list-item
        >
        <!-- <remito-consultar-lista
          :paginacion="paginacion"
          @set-page="setPage"
          ref="consultarPedidos"
          :remitos="remitos"
          @abrir-vista-previa=""
        ></remito-consultar-lista> -->
                <FacturaCompraList
          :facturas="remitos"
          @set-page="setPage"
          :paginacion="paginacion"
          @abrir-vista-previa="setRemito"
        ></FacturaCompraList>

        <remito-consultar-item-drawer v-if="drawerRemitoConsultar" :drawer="drawerRemitoConsultar" @set-drawer="setDrawer"  :remito="remitoSeleccionado"></remito-consultar-item-drawer>
      </v-container>
    </template>
  </LayoutBasic>
</template>

<script>
import RemitoBuscador from "@/views/remitos/remito.buscador.vue";
import LayoutBasic from "@/layouts/LayoutBasic.vue";;
import FacturaCompraList from "@/components/compra/factura/FacturaCompraList.vue";

import ProveedorListSelect from "@/components/proveedor/proveedor.select";
import RemitoConsultarItemDrawer from "@/components/compra/remito/remito.consultar.item.drawer.vue"

export default {
  name: "remito.consultar",
  components: {
    FacturaCompraList,
    LayoutBasic,
    RemitoBuscador,
    ProveedorListSelect,
    RemitoConsultarItemDrawer
  },

  data() {
    return {
      remitoSeleccionado: null,
      paginacion: { page: 1, cantidad: 0, rowsPerPage: 10 },
      drawerRemitoConsultar: false,
      remitos: [],
      loading: false,
      estados: ["Pagado", "Pendiente De Pago"],
      estado: null,
      dates: [new Date().toISOString().substr(0, 10)],
      menu2: false,
      menu: false,
      fechaInicio: new Date().toISOString().substr(0, 10),
      fechaFin: new Date().toISOString().substr(0, 10),
      proveedor: null,
      item: null,
      items: [
        {
          text: "Nuevo pedido",
          icon: "mdi-clock",
          route: "nuevoPedidoCompra",
        },
        {
          text: "Consultar",
          icon: "mdi-account",
          route: "pedidosCompraConsultar",
        },
        { text: "Historial", icon: "mdi-flag", route: "#" },
      ],
    };
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },

    navigation() {
      return [
        {
          text: "Compras",
          disabled: false,
          to: { name: "compras" },
        },
        {
          text: "Facturas",
          disabled: false,
          to: { name: "facturaCompra" },
        },
        {
          text: "Consultar",
          disabled: false,
          to: { name: "remitosConsultar" },
        },
      ];
    },
  },
  methods: {
     setDrawer(val){
      this.drawerRemitoConsultar = val
    },
    setRemito(remito){
      this.drawerRemitoConsultar = true
      this.remitoSeleccionado = remito
    },
    setProveedor(data) {
      this.proveedor = data;
      this.fetchRemitos();
    },
    setPedidosfromBuscador(data) {
      this.remitos = data.results;
        this.paginacion.cantidad = data.count;

    },
    fetchRemitos() {
      //REFACTORIZAR ESTO; ASCO
      this.loading = true;
      var esVenta = false;

      var estaPagado = null;
      var filters = "";
      if (this.estado) {
        if (this.estado == "Pagado") {
          estaPagado = true;
          filters = "ep=True";
        } else {
          estaPagado = false;
          filters = "ep=False";
        }
      }
      var url = `/comprobantes/comprobantes?esVenta=${esVenta}&doc=FC|RE&page=${this.paginacion.page}&page_size=${this.paginacion.rowsPerPage}&fecha_contable_after=${this.fechaInicio}&fecha_contable_before=${this.fechaFin}&${filters}`;
      if (this.proveedor) {
        var url = `/comprobantes/comprobantes?esVenta=${esVenta}&doc=FC|RE&page=${this.paginacion.page}&page_size=${this.paginacion.rowsPerPage}&proveedor=${this.proveedor.id}&fecha_contable_after=${this.fechaInicio}&fecha_contable_before=${this.fechaFin}&${filters}`;
      }
      this.axios.get(url).then((response) => {
        this.remitos = response.data.results;
        this.paginacion.cantidad = response.data.count;

        this.loading = false;
      });
    },
    setPage(page) {
      this.loading = true;
      this.paginacion.page = page;
      this.fetchRemitos();
    },
  },
  created() {
    this.fetchRemitos();
  },
};
</script>

<style lang="scss" scoped></style>
