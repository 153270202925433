<template>
  <div>
    <v-card class="transparent" flat v-if="cliente">
      <v-card-title class="pl-0 pr-0">
        <div class="headline">Contabilidad</div>
        <v-spacer> </v-spacer>
      </v-card-title>
      <v-card-text>
        <v-row justify="center">
          <v-col cols="12">
            <ClienteFormContabilidad
              @close-dialog="$emit('close-dialog')"
              :cliente="cliente"
              :isUpdate="false"
              @process-cliente="registrarContabilidad"
              :planDeCuentas="planDeCuentas"
            ></ClienteFormContabilidad>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import LayoutDialogDelete from "@/layouts/LayoutDialogDelete";
import ClienteFormContabilidad from "@/components/cliente/ClienteFormContabilidad";
import { fetchCliente } from "@/mixins/cliente/fetchCliente";

import { mapActions, mapState } from "vuex";

export default {
  name: "ClienteContabilidad",
  mixins: [fetchCliente],

  components: {
    LayoutDialogDelete,
    ClienteFormContabilidad,
  },

  data() {
    return {
      noImage: require("@/assets/man-woman.jpg"),
      provincias: [],
    };
  },
  computed: {
    ...mapState("contabilidad", ["planDeCuentas"]),
  },

  methods: {
    ...mapActions("contabilidad", ["fetchPlanDeCuentas"]),
    ...mapActions("cliente", ["actualizar_cliente_contable"]),

    async fetchCuentas() {
      try {
        
        
        await this.fetchPlanDeCuentas({ empresaUuid: null, isActive: true });
      } catch (error) {
        console.error("Error al obtener el plan de cuentas:", error);
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "info",
          text: "Error al obtener el plan de cuentas",
          icon: "mdi-information",
          timeout: 3000,
        });
      }
    },
    async registrarContabilidad(cliente) {
      try {
        const datosParaEnviar = {
          id: cliente.id,
          condicionIva: cliente.condicionIva,
          cuenta_corriente: cliente.cuenta_corriente
            ? cliente.cuenta_corriente.id
            : null,
          cuenta_contado: cliente.cuenta_contado
            ? cliente.cuenta_contado.id
            : null,
          cuenta_otros: cliente.cuenta_otros ? cliente.cuenta_otros.id : null,
          rubro_ventas: cliente.rubro_ventas ? cliente.rubro_ventas.id : null,
        };
        const response = await this.actualizar_cliente_contable(
          datosParaEnviar
        );
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: "Actualizado",
          icon: "mdi-check",
          timeout: 3000,
        });
        console.log("Rubro de venta registrado correctamente:", response.data);

        // Redirigir a la lista de rubros de venta o mostrar un mensaje de éxito
      } catch (error) {
        console.error("Error al registrar el rubro de venta:", error);
        // Mostrar un mensaje de error
      }
    },
    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
  },
  created() {
    this.fetchCuentas();
  },
};
</script>

<style lang="scss" scoped></style>
