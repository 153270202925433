<template>
  <div>
     <v-toolbar flat>
      <v-toolbar-title>Cuenta nueva</v-toolbar-title>
    </v-toolbar>

<v-list>
      <v-list-item style="background-color: transparent" flat>
        <v-list-item-icon>
          <v-icon>mdi-folder</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Cuenta principal: {{ parent.codigo }} - {{ parent.nombre }}</v-list-item-title>
          <v-list-item-subtitle>Esta es la carpeta principal a la que se agregará una nueva subcuenta.</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>

    <ContabilidadForm :cuenta="cuenta" @cerrar-dialog="closeDialog" @process-data="registrarCuenta"></ContabilidadForm>
  </div>
</template>

<script>
import ContabilidadForm from "@/components/contabilidad/ContabilidadForm.vue";
import { mapActions } from 'vuex';
export default {
  name: "ContabilidadRegister",
  components: {
    ContabilidadForm,
  },
  props: {
    parent: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      cuenta: {
        nombre: "",
        codigo: this.parent.codigo,
        parent_id: null,
      },
    };
  },
  methods: {
    ...mapActions("contabilidad", ["registrar_cuenta"]),
    registrarCuenta(cuenta){
        cuenta.parent_id = this.parent.id
        this.registrar_cuenta(cuenta).then(response => {
        this.$emit('set_cuenta', response.data)
    }).catch(e => {
        this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "info",
            text: `${e.response.data.detail}`,
            icon: "mdi-information",
            timeout: 3000,
          });
    })
    },
    closeDialog(){
        this.$emit('close-dialog')
    }
  }
};
</script>

<style lang="scss" scoped>
</style>