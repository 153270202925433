var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.sucursal)?_c('LayoutBasic',{attrs:{"showLinksMenu":false,"routeBack":-1},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(){return [_c('v-breadcrumbs',{staticClass:"pa-3 ml-1",attrs:{"items":_vm.navigation,"divider":">"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return _c('v-breadcrumbs-item',{attrs:{"exact":"","to":item.to}},[_vm._v(_vm._s(item.text))])}}],null,false,3095512748)})]},proxy:true},{key:"navigation-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.sucursal.nombre)+" ")]},proxy:true},{key:"navigation-subtitle",fn:function(){return [_vm._v(" "+_vm._s(_vm.sucursal.empresa.razonSocial)+" ")]},proxy:true},{key:"navigation-content",fn:function(){return [_c('v-divider',{attrs:{"light":""}}),_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({ name: 'sucursalDatosGenerales' })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-clock")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Datos")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
            name: 'sucursalEditar',
            params: { sucursal: _vm.sucursal },
          })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-clock")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Editar sucursal")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
            name: 'sucursalDueño',
            params: { sucursal: _vm.sucursal },
          })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-clock")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Dueños")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
            name: 'sucursalesEmpleados',
            params: { sucursal: _vm.sucursal },
          })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-clock")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Empleados")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
            name: 'sucursalContabilidad',
            params: { sucursal: _vm.sucursal },
          })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-clock")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Contabilidad")])],1)],1)],1)]},proxy:true},{key:"vista-previa",fn:function(){return [_c('v-container',[_c('router-view',{attrs:{"sucursal":_vm.sucursal}})],1)]},proxy:true}],null,false,2855411979)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }