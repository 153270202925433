<template>
  <v-dialog persistent width="400" v-model="dialogCuentaCorriente">
    <v-card>
      <v-container>
        <v-card-title
          >Cuenta corriente <v-spacer></v-spacer>
          <v-btn @click="$emit('close-dialog')" icon>
            <v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>

        <v-card v-if="clienteVenta" color="#303030" flat class="mx-2 pb-2">
          <v-container>
            <template v-if="cuentaCorriente">
              <v-card-title>
                {{ clienteVenta.nombre }} {{ clienteVenta.apellido }}
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="removeCliente" icon>
                      <v-icon>mdi-swap-horizontal</v-icon></v-btn
                    >
                  </template>
                  <span>Cambiar cliente</span>
                </v-tooltip>
              </v-card-title>

              <v-divider></v-divider>
              <v-card-text v-if="cuentaCorriente.is_habilitado">
                <v-list-item v-if="cuentaCorriente.tiene_limite_venta">
                  <v-list-item-content>
                    <v-list-item-title>
                      <div>Saldo disponible</div>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <h2>
                      {{ cuentaCorriente.saldo_disponible | convertPesoArg }}
                    </h2>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item v-else>
                  <v-list-item-content>
                    <v-list-item-title>
                      <div>Sin limite</div>
                    </v-list-item-title>
                  </v-list-item-content>
                
                </v-list-item>
                <h3 class="text-center">¿Cuánto deseas dejar en cuenta?</h3>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-text-field
                        autofocus
                        dense
                        outlined
                        v-model="montoCuentaCorriente"
                        type="number"
                        min="0"
                        max="10"
                      >
                      </v-text-field>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-alert
                  type="warning"
                  v-if="cuentaCorriente.tiene_limite_venta && montoCuentaCorriente > cuentaCorriente.saldo_disponible"
                  >Saldo insuficiente</v-alert
                >
              </v-card-text>
              <v-card-text v-else>
                <v-alert type="warning">La cuenta esta deshabilitada</v-alert>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="cancel"> Cancelar </v-btn>
                <v-btn
                  v-if="cuentaCorriente.is_habilitado"
                  color="green darken-1"
                  :disabled="
                    cuentaCorriente.tiene_limite_venta && montoCuentaCorriente > cuentaCorriente.saldo_disponible
                  "
                  @click="setMonto"
                >
                  Aplicar
                </v-btn>
              </v-card-actions>
            </template>
            <template v-else
              ><v-card-text
                ><v-alert type="info" dense>
                  <h3 class="text-h5">{{ clienteVenta.nombre }} {{ clienteVenta.apellido }}</h3>
                  <div>
                     No
                    tiene una cuenta corriente abierta
                  </div>
                </v-alert>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="removeCliente"> Cancelar </v-btn>
              </v-card-actions>
            </template>
          </v-container>
        </v-card>
        <v-card v-else color="#303030" flat class="mx-2 pb-2">
          <v-container>
            <venta-cliente-seleccionar
              title="Seleccionar cliente"
            ></venta-cliente-seleccionar>
          </v-container>
        </v-card>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import VentaClienteSeleccionar from "@/components/venta/cliente/venta.cliente.seleccionar.vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "formadepago.ctacte",
  components: {
    VentaClienteSeleccionar,
  },
  props: {
    formaDePago: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dialogCuentaCorriente: true,
      habilitarBotonCtaCte: false,
    };
  },
  computed: {
    ...mapState("ventas", ["clienteVenta"]),
    ...mapState("clienteCuentaCorriente", ["cuentaCorriente"]),
    montoCuentaCorriente: {
      get() {
        return this.$store.getters[
          "ventas/get_monto_forma_de_pago_seleccionada"
        ](this.formaDePago.nombre);
      },
      set(monto) {
        var nombre = this.formaDePago.nombre;
        this.agregar_monto_forma_de_pago({
          descripcion: nombre,
          monto: monto,
        });
      },
    },
  },
  methods: {
    ...mapActions("ventas", ["agregar_monto_forma_de_pago"]),
    setMonto() {
      var descripcion = this.formaDePago.nombre;
      this.agregar_monto_forma_de_pago({
        descripcion: descripcion,
        monto: this.montoCuentaCorriente,
      });
      this.closeDialog();
    },

    ...mapMutations("ventas", ["SET_CLIENTE_VENTA"]),

    ...mapActions("clienteCuentaCorriente", ["getCuentaCorriente"]),
    cancel() {
      this.$emit("quitar-forma-pago", this.formaDePago);
      this.closeDialog();
    },
    // setMonto() {
    //   this.$emit("set-monto-forma-pago", this.montoCuentaCorriente);
    // },
    closeDialog() {
      this.$emit("close-dialog");
    },
    openDialogCtaCte() {
      this.dialogCuentaCorriente = true;
    },
    removeCliente() {
      this.SET_CLIENTE_VENTA(null);
    },
  },
  mounted() {
    window.addEventListener("keyup", (event) => {
      // if (this.dialogCobrar) {
      if (event.key === "Enter") {
        console.log("Key enter formadepago.ctacte");
        event.stopPropagation();
      }
    });
  },
  watch: {
    clienteVenta(val) {
      console.log("WATCH CLIENTE VENTA", val);
      if (val) {
        this.getCuentaCorriente(val.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>