<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-list style="background-color: transparent" flat two-line>
          <v-list-item>
            <v-list-item-avatar>
              <v-btn icon @click="$router.go(-1)">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <div class="title">Nuevo rubro de compra</div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="8">
        <RubroCompraForm
          :planDeCuentas="planDeCuentas"
          :rubroCompra="rubroCompra"
          @register:rubro="submitForm"
        ></RubroCompraForm>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";
import RubroCompraForm from "@/components/contabilidad/RubroCompraForm.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "ContabilidadRubroCompraRegistrar",
  components: {
    LayoutBasic,
    RubroCompraForm,
  },
  props: {
    planDeCuentas: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      rubroCompra: {
        descripcion: "",
        codigo: "",

        cuenta_neto_gravado: null,
        cuenta_iva_no_inscripto: null,
        cuenta_neto_no_gravado_exento: null,
        cuenta_iva: null,
        cuenta_iva_per_ret: null,
        cuenta_ing_bru: null,
        cuenta_ganancia: null,
        cuenta_o_conc_3: null,
      },
    };
  },

  computed: {
    ...mapState("contabilidad", ["planDeCuentas"]),
  },
  methods: {
    ...mapActions("contabilidad", ["registrar_rubro_compra",
    ]),
    async submitForm() {
      try {
        const datosParaEnviar = {
          ...this.rubroCompra,
          cuenta_neto_gravado: this.rubroCompra.cuenta_neto_gravado
            ? this.rubroCompra.cuenta_neto_gravado.id
            : null,
          cuenta_iva_no_inscripto: this.rubroCompra.cuenta_iva_no_inscripto
            ? this.rubroCompra.cuenta_iva_no_inscripto.id
            : null,
          cuenta_neto_no_gravado_exento: this.rubroCompra
            .cuenta_neto_no_gravado_exento
            ? this.rubroCompra.cuenta_neto_no_gravado_exento.id
            : null,
          cuenta_iva: this.rubroCompra.cuenta_iva
            ? this.rubroCompra.cuenta_iva.id
            : null,
          cuenta_iva_per_ret: this.rubroCompra.cuenta_iva_per_ret
            ? this.rubroCompra.cuenta_iva_per_ret.id
            : null,
          cuenta_ing_bru: this.rubroCompra.cuenta_ing_bru
            ? this.rubroCompra.cuenta_ing_bru.id
            : null,
          cuenta_ganancia: this.rubroCompra.cuenta_ganancia
            ? this.rubroCompra.cuenta_ganancia.id
            : null,
          cuenta_o_conc_3: this.rubroCompra.cuenta_o_conc_3
            ? this.rubroCompra.cuenta_o_conc_3.id
            : null,
        };
        console.log(datosParaEnviar);
        const response = await this.registrar_rubro_compra(datosParaEnviar);
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: "Rubro de venta registrado",
          icon: "mdi-check",
          timeout: 3000,
        });
        this.$router.push("/contabilidad/rubrocompra");
        console.log("Rubro de venta registrado correctamente:", response.data);
        // Redirigir a la lista de rubros de venta o mostrar un mensaje de éxito
      } catch (e) {
        console.log(e.response.data);
        // Construir el mensaje de error a partir del objeto de error
        let errorMessage = "Ocurrió un error desconocido";

        if (e.response.data) {
          // Crear un array para almacenar los mensajes de error
          let errorMessages = [];

          // Recorrer las propiedades del objeto de error
          for (let key in e.response.data) {
            if (e.response.data.hasOwnProperty(key)) {
              // Combinar el nombre del campo y los mensajes de error
              let messages = e.response.data[key];
              if (Array.isArray(messages)) {
                messages.forEach((message) => {
                  errorMessages.push(`${key}: ${message}`);
                });
              } else {
                errorMessages.push(`${key}: ${messages}`);
              }
            }
          }

          // Unir todos los mensajes de error en una sola cadena
          errorMessage = errorMessages.join(", ");
        }

        // Emitir el evento para mostrar el snackbar con el mensaje de error
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "info",
          text: errorMessage,
          icon: "mdi-information",
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
