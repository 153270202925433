<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="internalDate"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="formattedDate"
        :label="label"
        :prepend-icon="showIcon ? 'mdi-calendar' : ''"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="internalDate"
      no-title
      scrollable
      @input="saveDate" 
    >
     
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "DatePickerDate",
  props: {
    // La fecha inicial que recibe del componente padre
    value: {
      type: String,
      default: () => (new Date().toISOString().substr(0, 10)), // Fecha actual
    },
    label: {
      type: String,
      default: () => 'Fecha'
    },
    showIcon: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    formattedDate() {
      // Formatea la fecha interna usando Moment.js
      return this.internalDate
        ? this.moment(this.internalDate).format("LL") // Ajusta el formato según tus necesidades
        : "";
    },
  },
  data() {
    return {
      internalDate: this.value, // Fecha manejada internamente
      menu: false,
    };
  },
  watch: {
    // Cuando la prop cambia en el padre, actualiza el valor interno
    value(newVal) {
      this.internalDate = newVal;
    },
  },
  methods: {
    saveDate() {
      this.$refs.menu.save(this.internalDate);
      this.menu = false;
      // Emitir el cambio al componente padre
      this.$emit("input", this.internalDate);
    },
  },
};
</script>
