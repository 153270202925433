<template>
  <v-card>
    <v-container>
      <v-card-title class="justify-center">
        <span v-if="factura.codigo"> {{ factura.codigo }} </span>
        <span v-else> id {{ factura.id }} </span>
        <v-spacer></v-spacer>
        <v-chip
          v-if="factura.estado_pago === 'Pagado'"
          label
          class="ma-2 green darken-2 pa-2 text-capitalize"
        >
          <v-avatar left>
            <v-icon>mdi-check</v-icon>
          </v-avatar>
          Pagado
        </v-chip>
        <v-chip
          v-else-if="factura.estado_pago === 'Pendiente de pago'"
          label
          class="ma-2 orange darken-4 pa-2 text-capitalize"
        >
          <v-avatar left>
            <v-icon>mdi-cash</v-icon>
          </v-avatar>
          Pendiente de pago
        </v-chip>
        <v-chip
          v-else-if="factura.estado_pago === 'Parcialmente pagado'"
          label
          class="ma-2 blue darken-2 pa-2 text-capitalize"
        >
          <v-avatar left>
            <v-icon>mdi-timer-sand</v-icon>
          </v-avatar>
          Parcialmente pagado
        </v-chip>
        <v-chip v-else label class="ma-2 grey darken-2 pa-2 text-capitalize">
          <v-avatar left>
            <v-icon>mdi-alert-circle</v-icon>
          </v-avatar>
          Desconocido
        </v-chip>

        <v-btn large color="grey" icon @click="$emit('close-dialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="6">
          <v-list two-line>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-calendar</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ moment(factura.fechaAlta).format("lll") }}
                </v-list-item-title>
                <v-list-item-subtitle> Fecha de alta </v-list-item-subtitle>
              </v-list-item-content></v-list-item
            >
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-calendar</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ moment(factura.fecha_contable).format("lll") }}
                </v-list-item-title>
                <v-list-item-subtitle> Fecha contable </v-list-item-subtitle>
              </v-list-item-content></v-list-item
            >
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-alpha-p-circle</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ factura.proveedor.nombre }}
                </v-list-item-title>
                <v-list-item-subtitle> Proveedor </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-cash</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ factura.saldo | convertPesoArg }}
                </v-list-item-title>
                <v-list-item-subtitle> Pago pendiente </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-btn block @click="verRemito(factura)" color="indigo accent-4"
            >Ver factura
            <v-icon class="ml-3">mdi-book-open-outline</v-icon></v-btn
          >

          <iframe ref="ticketFrame" style="display: none"></iframe>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="6">
          <v-list v-if="factura.pagos.length > 0">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> Historial de pagos </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="(pago, index) in factura.pagos" :key="index">
              <v-list-item-content>
                <v-list-item-title>{{ pago.formaDePago }}</v-list-item-title>
                <v-list-item-subtitle
                  >Monto: {{ pago.monto }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
            <v-alert
              v-if="factura.pagos.length > 0"
              dense
              type="info"
              color="blue"
              border="left"
              elevation="2"
            >
              Próximamente podrás ver los detalles de la fecha del pago y del
              recibo. ¡Mantente atento!
            </v-alert>
          </v-list>
          <div class="text-center mt-4">
            <v-btn
              v-if="!factura.estaPagado"
              @click="dialogCobrar = true"
              :loading="loadingButton"
              class="ml-7"
              x-large
              color="success"
            >
              Pagar
              <v-icon dark> mdi-check-bold </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialogCobrar" width="600">
      <ComprobanteCompraPagar
        @success-payment="successPayment()"
        v-if="dialogCobrar"
        @close-dialog-cobrar="dialogCobrar = false"
        :comprobante="factura"
      ></ComprobanteCompraPagar>
    </v-dialog>
    <dialog-confirmation-close
      v-if="dialogPedidoCancelar"
      @close-dialog="dialogPedidoCancelar = false"
      @process-accept="cancelarPedido"
    >
      <template v-slot:titulo>¿Cancelar el factura?</template>
      <template v-slot:message
        >¿Seguro que quieres cancelar el factura? No podrá revertir los
        cambios.</template
      >
      <template v-slot:buttonOK>Cancelar factura</template>
    </dialog-confirmation-close>
  </v-card>
</template>

<script>
import DialogConfirmationClose from "@/components/dialogs/confirmation.close";
import ComprobanteCompraPagar from "@/components/compra/ComprobanteCompraPagar.vue";

import { mapActions } from "vuex";

export default {
  name: "factura.compra.vista-previa",
  components: {
    DialogConfirmationClose,
    ComprobanteCompraPagar,
  },
  props: {
    factura: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogPedidoCancelar: false,
      loadingButton: false,
      montoActual: 0,
      dialogCobrar: false,
    };
  },
  computed: {
    pedidosSiguientes() {
      var pedidos = [];
      this.factura.getEstadosSiguientesDescripcion.forEach((element) => {
        if (element.accion) {
          pedidos.push(element);
        }
      });
      return pedidos;
    },
  },
  methods: {
    ...mapActions("productos", ["fetch_productos_mas_vendidos"]),

    ...mapActions("remitos", ["pagar_remito"]),
    // verRemito() {
    //   this.$emit("process-ver-factura", this.factura);
    // },
    async getMontoActual() {
      const response = await this.axios.get("/sucursales/arqueo/monto");

      this.montoActual = response.data.saldo;
    },
    successPayment() {
      this.$emit("refresh");
      this.$emit("close-dialog");
      this.fetch_productos_mas_vendidos();
      this.loadingButton = false;
    },

    cancelarPedido() {
      //AGREGAR MODAL PARA CONFIRMACION
      let url = `pedidos/actestado/${this.factura.id}/CANCELADO`;
      this.axios
        .get(url)
        .then((response) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: `Remito cancelado`,
            icon: "mdi-check",
            timeout: 3000,
          });
          this.$emit("process-quitar-factura", this.factura);
        })
        .catch((e) => {});
    },

    fetchPedido(id) {
      var url = `/comprobantes/comprobantes/${id_remito}`;

      this.axios.get(url).then((response) => {
        this.factura = response.data;
        this.detalles_remito = response.data.detalles_comprobante;
      });
    },

    async verRemito(factura) {
      this.$router.push({
        name: "facturaItem",
        params: { id: factura.id },
      });
    },
  },
};
</script>

<style scoped>
#buttonAccion {
  text-transform: lowercase;
}

#buttonAccion > span {
  display: inline-block;
}

#buttonAccion > span:first-letter {
  text-transform: uppercase;
}
</style>
