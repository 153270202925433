<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="facturas"
      class="row-pointer transparent"
      no-data-text="No se encontraron resultados"
      @click:row="handleClick"
      hide-default-footer
    >
      <!-- <template v-slot:item.fechaAlta="{ item }">
        {{ moment(item.fechaAlta).startOf("minutes").fromNow() }}
      </template> -->
      <template v-slot:item.fechaAlta="{ item }">
        {{ moment(item.fechaAlta).format('ll') }}

      </template>
      <template v-slot:item.fecha_contable="{ item }">
        {{ moment(item.fecha_contable).format('ll') }}
      </template>
      <template v-slot:item.estado_pago="{ item }">
        <v-chip
          v-if="item.estado_pago === 'Pagado'"
          label
          class="ma-2 green darken-2 pa-2 text-capitalize"
        >
          <v-avatar left>
            <v-icon>mdi-check</v-icon>
          </v-avatar>
          Pagado
        </v-chip>
        <v-chip
          v-else-if="item.estado_pago === 'Pendiente de pago'"
          label
          class="ma-2 orange darken-4 pa-2 text-capitalize"
        >
          <v-avatar left>
            <v-icon>mdi-cash</v-icon>
          </v-avatar>
          Pendiente de pago
        </v-chip>
        <v-chip
          v-else-if="item.estado_pago === 'Parcialmente pagado'"
          label
          class="ma-2 blue darken-2 pa-2 text-capitalize"
        >
          <v-avatar left>
            <v-icon>mdi-timer-sand</v-icon>
          </v-avatar>
          Parcialmente pagado
        </v-chip>
        <v-chip v-else label class="ma-2 grey darken-2 pa-2 text-capitalize">
          <v-avatar left>
            <v-icon>mdi-alert-circle</v-icon>
          </v-avatar>
          Desconocido
        </v-chip>
      </template>
    </v-data-table>
    <v-row>
      <v-col>
        <v-pagination
          v-if="facturas"
          @input="paginationChangeHandler"
          :length="pages"
          :value="paginacion.page"
          circle
        ></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import DetallePedidoForm from "@/components/pedido/DetallePedidoForm";

export default {
  name: "FacturaCompraList",
  components: {
    DetallePedidoForm,
  },
  props: {
    facturas: {
      type: Array,
      required: true,
    },
    paginacion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "Fecha Creación", value: "fechaAlta", sortable: false },
        { text: "Fecha Contable", value: "fecha_contable", sortable: false },

        // { text: "Creacion", value: "fechaAlta", sortable: false },

        { text: "Nº", value: "codigo", sortable: false },
        { text: "Proveedor", value: "proveedor.nombre", sortable: false },
        { text: "Estado", value: "estado_pago", sortable: false },
      ],
    };
  },

  computed: {
    pages() {
      if (this.paginacion.rowsPerPage == null || this.paginacion.cantidad < 0) {
        return 0;
      }

      return Math.ceil(this.paginacion.cantidad / this.paginacion.rowsPerPage);
    },
  },
  methods: {
    paginationChangeHandler(pageNumber) {
      if (this.paginacion.page == pageNumber) {
        return false;
      }
      this.$emit("set-page", pageNumber);
    },
    handleClick(pedido) {
      this.$emit("abrir-vista-previa", pedido);
    },
    async abrirPedido(pedido) {
      this.$router.push({
        name: "ActualizarPedido",
      });
    },
  },
  created() {},
};
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
