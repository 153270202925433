<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <div class="headline">Editar perfil</div>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12">
        <form-proveedor
          v-if="proveedor"
          textButton="Actualizar"
          @close-dialog="$emit('close-dialog')"
          :proveedor="proveedor"
          :provincias="provincias"
          :isUpdate="true"
          @processProveedor="actualizarProveedor"
          :planDeCuentas="planDeCuentas"
        ></form-proveedor>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormProveedor from "@/components/proveedor/FormProveedor";
import { mapActions, mapState } from "vuex";
import { fetchProveedor } from "@/mixins/proveedor/fetchProveedor";
export default {
  name: "proveedor-update",
  components: {
    FormProveedor,
  },
  mixins: [fetchProveedor],

  data() {
    return {
      provincias: [],
    };
  },

  computed: {
    ...mapState("contabilidad", ["planDeCuentas"]),
  },
  methods: {
    ...mapActions("contabilidad", ["fetchPlanDeCuentas"]),
    ...mapActions("proveedor", ["actualizar_proveedor"]),

    async fetchCuentas() {
      try {
        await this.fetchPlanDeCuentas({ empresaUuid: null, isActive: true });
      } catch (error) {
        console.error("Error al obtener el plan de cuentas:", error);
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "info",
          text: "Error al obtener el plan de cuentas",
          icon: "mdi-information",
          timeout: 3000,
        });
      }
    },

    actualizarProveedor(proveedor) {
      this.actualizar_proveedor(proveedor).then(() => {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: "Proveedor actualizado",
          icon: "mdi-bell-ring",
          timeout: 3000,
        });
        this.$router.push({ path: `/proveedores/${proveedor.id}` });
      });
    },
    async fetchProvincias() {
      try {
        const provincias = await this.axios.get("/soporte/provincias");

        this.provincias = provincias.data;
      } catch (error) {}
    },
  },
  mounted() {
    this.fetchProvincias();
    this.fetchCuentas();
  },
};
</script>

<style lang="scss" scoped></style>
