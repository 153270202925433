<template>
  <div class="sucursal-contable-container">
    <h1>Sucursal Contable</h1>

    <div class="status-section">
      <template v-if="isLoading">
        <v-progress-circular indeterminate color="primary" />
        <p>Consultando la información...</p>
      </template>

      <template v-else>
        <v-alert v-if="sucursalContable" type="success" outlined>
          La sucursal contable ya está configurada.
        </v-alert>

        <v-alert v-else type="warning" outlined>
          La sucursal contable no está configurada.
        </v-alert>
      </template>
    </div>

    <div class="actions-section" v-if="!isLoading">
      <v-btn
        color="primary"
        @click="consultarSucursalContable"
        :disabled="isLoading"
      >
        Consultar Sucursal Contable
      </v-btn>

      <v-btn
        v-if="!sucursalContable"
        color="success"
        @click="crearSucursalContable"
        :disabled="isLoading"
      >
        Crear Sucursal Contable
      </v-btn>
    </div>
  </div>
</template>
  
  
  <script>
export default {
  name: "SucursalContabilidad",
  props: {
    sucursal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sucursalContable: null, // Estado para mostrar si existe o no
      isLoading: false, // Indicador de carga
    };
  },
  methods: {
    async consultarSucursalContable() {
      try {
        this.isLoading = true;
        const sucursalUUID = this.sucursal.uuid;
        const existe = await this.$store.dispatch(
          "contabilidad/consultarSucursalContable",
          sucursalUUID
        );
        this.sucursalContable = existe;
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: "Consulta realizada con éxito.",
          icon: "mdi-check-circle",
          timeout: 3000,
        });
      } catch (error) {
        this.isLoading = false;
        this.sucursalContable = null;
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "error",
          text: error.message || "Error al consultar la Sucursal Contable.",
          icon: "mdi-alert-circle",
          timeout: 3000,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async crearSucursalContable() {
      try {
        this.isLoading = true;
        const sucursalUUID = this.sucursal.uuid;

        await this.$store.dispatch(
          "contabilidad/crearSucursalContable",
          sucursalUUID
        );
        this.sucursalContable = true; // Actualiza el estado
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: "Sucursal Contable creada correctamente.",
          icon: "mdi-check-circle",
          timeout: 3000,
        });
      } catch (error) {
        this.isLoading = false;

        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "error",
          text: error.message || "Error al crear la Sucursal Contable.",
          icon: "mdi-alert-circle",
          timeout: 3000,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted(){
    this.consultarSucursalContable()
  }
};
</script>
  
  <style lang="scss" scoped>
/* Estilos personalizados */
</style>
  